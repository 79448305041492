
.about_wrapper {
    display: flex;
    column-gap: 2rem;
}

.about_content, 
.about_img {
    width: 50%;
}

.about_img img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    border-radius: 0.8rem;
}

.about_content-desc {
    margin-top: 30px;

}

.choose_item-wrapper {
    margin-top: 20px;
}

.choose_us-item {
    padding: 20px 20px 20px 0;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 15px;
}

.choose_us-icon {
    width: 50px;
    height: 60px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}

.choose_us-icon i {
    color: var(--primary-color);
    font-size: 2rem;
}

.choose_us-title {
    color: #fff;
    font-weight: 500;
}

.light-theme .choose_us-title {
    color: #0c123d!important;
}

@media only screen and (max-width:992px) {
    .about_content h2 {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width:768px) {
    .about_wrapper {
        flex-direction: column;
    }

    .about_content, 
    .about.img {
        width: 100%;
    }

    .about_img img {
        height: 400px!important;
    }

    .about_content {
        margin-bottom: 30px;
    }
}