
.slider_content-top {
    text-align: center;
    margin-bottom: 50px;
}

.slider_wrapper {
    width: 70%;
    margin: auto;
}

.slider_item {
    background: var(--testimonial-bg);
    padding: 40px 30px;
    border-radius: 0.5rem;
    text-align: center;
}

.description {
    text-align: start;
}

.customer_details {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 40px;
    cursor: pointer;
}

.customer_img {
    width: 50px;
    height: 50px;
    
}

.customer_img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.customer_name {
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
}

.light-theme .customer_name {
    color: black;
}

@media only screen and (max-width:992px) {
    .slider_content-top h2 {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width:768px) {
    .slider_wrapper {
        width: 90%;
    }

    .customer_name {
        font-size: 1rem;
    }
}