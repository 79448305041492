/* Default CSS */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    /* background-color: #121212 !important; */
    background-color: var(--body-bg);
    color: #fff !important;
    font-family: 'Galibier',alibaba ,sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
}

p, a, li, button, ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img {
    width: 100%;
    height: auto;
}

button {
    border: 0;
    background-color: transparent;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

@media (min-width:1700px) {
    main .container {
        max-width: 100%;
        padding: 0 150px;
    }
}

p.success {
    color: green;
}

p.danger {
    color: red;
}

.theme-color {
    color: #FFFF00!important;
}



/************ Navbar CSS ************/


nav.navbar {
    padding: 18px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition:  0.32s ease-in-out;
  }
  nav.navbar.scrolled {
    padding: 0px 0;
    background-color: #121212;
  }
  nav.navbar a.navbar-brand h2 {
    font-weight: 700;
    color: #fff;
  }
  nav.navbar .navbar-nav .nav-link {
    font-weight: 400;
    color: #fff !important;
    letter-spacing: 0.8px;
    padding: 0 25px;
    font-size: 14px;
    opacity: 0.75;
  }
  nav.navbar .navbar-nav a.nav-link:hover,
  nav.navbar .navbar-nav a.nav-link.active {
      opacity: 1;
  }
  
  nav.navbar .navbar-nav .dropdown-menu {
    /* background: #FFFF00; */
    border: none;
  }
  
  nav.navbar .navbar-nav .dropdown-menu a {
    color: #000;
    font-weight: 600;
    margin: 0.5rem auto;
  }
  
  nav.navbar .navbar-nav .dropdown-menu .dropdown-item:active,
  nav.navbar .navbar-nav .dropdown-menu .dropdown-item.active{
    background: #FFFF00!important;
  }
  
  span.navbar-text {
      display: flex;
      align-items: center;
  }
  .social-icon {
      display: inline-block;
      margin-left: 14px;
  }
  .social-icon a {
      width: 42px;
      height: 42px;
      background: rgba(217, 217, 217, 0.1);
      display: inline-flex;
      border-radius: 50%;
      margin-right: 6px;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border: 1px solid rgba(255, 255, 255, 0.5);
  }
  .social-icon a::before {
      content: "";
      width: 42px;
      height: 42px;
      position: absolute;
      background-color: #ffffff;
      border-radius: 50%;
      transform: scale(0);
      transition: 0.3s ease-in-out;
  }
  .social-icon a:hover::before {
      transform: scale(1);
  }
  .social-icon a img {
      width: 40%;
      z-index: 1;
      transition: 0.3s ease-in-out;
  }
  .social-icon a:hover img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
  }
  .navbar-text button {
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    padding: 12px 10px;
    font-size: 14px;
    margin-left: 18px;
      position: relative;
      background-color: transparent;
      transition: 0.3s ease-in-out;
  }
  .navbar-text button span {
    z-index: 1;
  }
  .navbar-text button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  .navbar-text button:hover {
    color: #121212;
  }
  .navbar-text button:hover::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
  }
  nav.navbar .navbar-toggler:active,
  nav.navbar .navbar-toggler:focus {
      outline: none;
      box-shadow: none;
  }
  nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
  }
  nav.navbar .navbar-toggler-icon:focus {
      border-bottom: 2px solid #fff;
  }
  nav.navbar .navbar-toggler-icon:after,
  nav.navbar .navbar-toggler-icon:before {
      width: 24px;
      position: absolute;
      height: 2px;
      background-color: #fff;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 300ms linear;
  }
  nav.navbar .navbar-toggler-icon:after {
      top: 8px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
      transform: rotate(45deg);
      background-color: #fff;
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
      transform: translateY(8px) rotate(-45deg);
      background-color: #fff;
      height: 2px;
  }
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
      border-color: transparent;
  }
  
  /* Logo Setting */
  .nav-logo2 {
    width: 100%;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
  .nav-logo2-a {
    width: 7%;
    height: auto;
  }
  
  @media only screen and (max-width:768px) {
    .nav-logo2 {
      width: 20%;
      margin: 4px auto;
    }
    .language {
      margin: 8px 0 12px;
      width: 20%;
      font-size: 14px;
      left: 17px!important;
      padding: 2px 4px;
    }
  }
  
  nav.navbar.scrolled .nav-logo {
    background-color: #fff;
  }
  
  .language {
    position: relative;
    left: 40px;
    border: 1px solid #fff;
    padding: 4px 15px;
    border-radius: 50px;
    background: #fff;
    -webkit-text-stroke: 1px #000;
    cursor: pointer;
  }
  .language:hover {
    transform: scale(1.2);
  }