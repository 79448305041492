.container {
    width: 100%;
}

.counter_des h2 {
    color: var(--primary-color);
    text-align: center;
    font-size: 3rem;
}

.light-theme .counter_des h2 {
    color: #fff;
}


.counter_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

}


.counter_item {
    width: 33%;
    text-align: center;
}

.counter_number,
.counter_title {
    background: var(--counter-color);
    background-size: 100% 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.counter_number {
    font-size: 2.5rem;
    margin-bottom: 10px;
}

.counter_title {
    font-size: 1.2rem;
}

@media only screen and (max-width:1024px) {
    .counter_number {
        font-size: 2rem;
    }

    .counter_title {
        font-size: 1.2rem;
    }

    .counter_item {
        width: 30%;
    }

    .counter_des h2 {
        font-size: 2rem;
    }
}

@media only screen and (max-width:768px) {
    .counter_number {
        font-size: 1.5rem;
    }

    .counter_title {
        font-size: 1rem;
    }
}

@media only screen and (max-width:576px) {

    .counter_wrapper {
        flex-direction: column;
    }

    .counter_item {
        width: 100%;
        margin-bottom: 30px;
    }
}