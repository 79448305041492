
.services_top-content {
    text-align: center;
    margin-bottom: 50px;
}

.service_item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1.5rem;
}

.service_item {
    background: var(--card-bg);
    width: 25%;
    padding: 30px 15px;
    text-align: center;
    border-radius: 0.5rem;
    /* cursor: pointer; */
    height: 25rem; 
}

.service_item:hover {
    transform: scale(1.2);
}

.service_item p {
    text-align: start;
}

.service_icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    background: var(--icon-bg);
    border-radius: 5px;
    margin-bottom: 30px;
    border: 1px solid var(--primary-color);
}

.light-theme .service_icon {
    background-color: var(--primary-color);
}

.light-theme .service_icon i {
    color: #fff;
}

.service_icon i {
    font-size: 2rem;
    color: var(--primary-color);
}

.service_title {
    color: var(--primary-color);
    font-size: 1.3rem;
    margin-bottom: 30px;
}

@media only screen and (max-width:992px) {
    .services_top-content h2 {
        font-size: 1.5rem;
    }

    .service_item-wrapper {
        flex-wrap: wrap;
    }

    .service_item {
        width: 31%;
        margin-bottom: 30px;
    }
    .service_item:hover {
        transform: none;
    }
}

@media only screen and (max-width:768px) {
    .service_item {
        width: 47.7%;
    }
    .service_title {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width:576px) {
    .service_item {
        width: 100%;
        margin: 0;
        padding: 0 2rem;
        height: 20rem;
    }
}