
.footer {
    padding: 60px 0;
}

.footer_wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 2.5rem;
}

.footer_logo {
    width: 40%;
    /* 暫時性修改 */
}

.footer_information {
    font-size: 1.1rem;
    color: aliceblue;
    line-height: 1.5;
}

.footer_information h3 {
    color: white;
}

.sales_manager {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--primary-color);
    margin-top: 5px;
    margin-bottom: 2px;
}

.light-theme .footer_information {
    color: #555;
}

.light-theme .footer_information h3 {
    color: #555;
}

.footer_quick-links {
    width: 20%;
}

.footer_logo h2 {
    color: var(--primary-color);
}

.small_text {
    font-size: 1rem;
    margin-top: 30px;
}

.quick_links-title {
    font-size: 1.1rem;
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 30px;
}

.quick_links {
    list-style: none;
}

.quick_link-item {
    margin-bottom: 30px;
}

.quick_link-item a {
    font-size: 0.9rem;
    font-weight: 300;
    cursor: pointer;
}

.quick_link-item a:hover {
    color: var(--primary-color );
}

/*-------------- Social Media --------------*/
.social_media i {
    font-size: 2.5rem;
}

.copyright {
    color: rgba(255, 255, 255, 0.573);
    margin-top: 40px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
}

.light-theme .quick_link-item a {
    font-weight: 500;
}

.light-theme .blog_item h3 {
    color: #555;
}

.light-theme .copyright {
    color: #555;
}

.project-1 .footer_information h3 {
    color: #d9b93e;
}
.project-2 .footer_information h3 {
    color: #d9b93e;
}
.project-3 .footer_information h3 {
    color: #d9b93e;
}

.ri-mail-fill {
    color: #d9b93e;
}

@media only screen and (max-width:768px) {
    .footer_logo {
        width: 55%;
    }

    .footer_wrapper {
        flex-wrap: wrap;
        column-gap: 1.5rem!important;
        row-gap: 1.5rem;
    }

    .footer_logo {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width:576px) {
    .footer_quick-links {
        width: 47%;
    }
    .footer_logo {
        width: 47%;
    }
}