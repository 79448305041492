/* Original Setting */
.project-1 .footer p {
    color: #000;
}
.project-1 a {
    color: #000;
}
.project-1 {
    /* background-color: white; */
    padding: 1rem 0 0 0 ;
}

/*------- Project1 Header --------*/
.project-1_title {
    color: var(--primary-color);
    text-align: center;
    font-size: 4rem;
}

.project1-content {
    margin: 3rem auto;
}

.project-1_des {
    padding: 1rem 10rem;
    text-align: center;
    line-height: 2.5;
    font-size: 1.5rem;
    color: #fff;
}
.light-theme .project-1_des {
    color: #000;
}

.porject1_header h3 {
    margin: 1rem auto 4rem;
    background-color: var(--primary-color);
    text-align: center;
    color: white;
    font-size: 2rem;
    width: 21rem;
    border-radius: 10rem;
}

/*------ Sense Markting -----*/
.sense_marketing {
    display: flex;
    background-color: #191919;
    color: #fff;
    padding: 3rem 0.5rem 3rem 2rem;
}

.sense_marketing-des {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sense_marketing-des h2 {
    font-size: 2.5rem;
    color: #fff;
}

.sense_marketing-des h3 {
    margin-bottom: 4rem;
    font-size: 1.5rem;
}

.sense_marketing-des p {
    text-align: start;
    line-height: 2.5;
    width: 18rem;
}

.sense_marketing-img {
    width: 60%;
}

.sense_marketing-img img {
    width: 100%;
}


/*------ Branding Part ------*/
.branding {
    display: flex;
}

.branding_mainImg {
    width: 36%;
}

.branding_mainImg img {
    width: 100%;
    height: auto;
}

.branding_des {
    width: 45%;
}

.branding_des h2 {
    color: #fff;
    text-align: center;
    margin-top: 3rem;
    font-size: 2.5rem;
}
.light-theme .branding_des h2 {
    color: #000;
}

.branding_des p {
    background-color: #191919;
    color: #fff;
    margin: auto 1rem;
    margin-top: 5rem;
    padding: 1rem 0.5rem;
    text-align: center;
    line-height: 3;
    font-size: 1.3rem;
}

.brandimg_images {
    width: 23%;
    display: flex;
    flex-direction: column;
}

.brandimg_images img {
    width: 100%;
}

/*-------- Character Design --------*/
.character_design {
    margin: 3rem auto;
}

.character_design-imagesup {
    display: flex;
}

.character_design-images1 {
    width: 26%;
    height: auto;
}

.character_design-images2 {
    width: 35%;
    height: auto;
    margin: auto 3.5%;
}

.character_design-images3 {
    width: 32%;
    height: auto;
}

.character_design-imagesdown {
    display: flex;
}

.character_design-imagesdown img {
    width: 32%;
    height: auto;
}

.character_design-images {
    margin: auto 2%;
}

.character_design-des {
    display: flex;
    justify-content: space-around;
    margin: 2rem auto 4rem;
}

.character_design-title {
    width: 30%;
}

.character_design-title h2 {
    color: #fff;
}
.light-theme .character_design-title h2 {
    color: #191919;
}

.character_design-title h5 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
}
.light-theme .character_design-title h5 {
    color: #000;
}

.character_design-des p {
    width: 40%;
    text-align: center;
    line-height: 2;
    padding-top: 1rem;
    color: #fff;
}
.light-theme .character_design-des p {
    color: #000;
}


/*----------- Package Design -----------*/
.package_design {
    display: flex;
    flex-wrap: wrap;
}

.package_design-des {
    width: 50%;
    padding: 3rem 3rem;
}

.package_design-des h2 {
    color: #fff;
    font-size: 2.8rem;
}
.light-theme .package_design-des h2 {
    color: #191919;
}

.package_design-des h3 {
    font-size: 1.8rem;
    color: #fff;
    background-color: var(--primary-color);
    width: 23rem;
    text-align: center;
    border-radius: 10px;
    margin-top: 2rem;
}

.package_design-block {
    display: flex;
    margin: 5rem auto
}

.package_design-block h4 {
    width: 10%;
    height: auto;
    font-size: 2.2rem;
    font-weight: 400;
    margin-top: 0.5rem;
    color: #fff;
}
.light-theme .package_design-block h4 {
    color: #000;
}

.package_design-word {
    padding-left: 0.2rem;
}

.package_design-word h5 {
    font-size: 1.8rem;
    color: #fff;
}
.light-theme .package_design-word h5 {
    color: #000;
}

.package_design-word ul {
    font-size: 1.5rem;
    line-height: 1.5;
    padding-left: 1rem;
    color: #fff;
}
.light-theme .package_design-word ul {
    color: #000;
}

.package_design-img {
    width: 50%;
    height: auto;
}

.package_design-img img {
    width: 100%;
    height: auto;
}

/*------------------ Social Design Part ---------------*/
.social_design {
    display: flex;
    justify-content: center;
    margin: 2rem;
    padding: 3rem;
}

.social_design-des {
    width: 50%;
}

.social_design-des h2 {
    color: #fff;
    font-size: 2.5rem;
}
.light-theme .social_design-des h2 {
    color: #000;
}

.social_design-des h3 {
    background-color: #d9b93e;
    font-size: 1.8rem;
    width: 23rem;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    color: #fff;
    margin-top: 1.5rem;
}

.social_design-des img {
    margin-top: 1rem;
    width: 80%;
}

.social_design-img {
    width: 50%;
}

.social_design-img img {
    width: 100%;
}


/* ------- Project Part ------- */
.project-block {
    display: flex;
    margin: 2rem auto;
    justify-content: space-around;
}

.project-block_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    text-align: center;
}

.project-block_title {
    color: var(--primary-color);
    font-size: 2.5rem;
}


.project-block_content h2 {
    color: #fff;
}
.light-theme .project-block_content h2 {
    color: #000;
}

.project-block_content h3 {
    margin-bottom: 2rem;
    color: #fff;
}
.light-theme .project-block_content h3 {
    color: #000;
}

.project-block_content p {
    text-align: center;
    width: 20rem;
    font-size: 1.3rem;
    color: #fff;
}
.light-theme .project-block_content p {
    color: #000;
}

.project-block_img {
    width: 30%;
}

.project-block_img img {
    width: 100%;
    height: auto;
    border-radius: 2rem;
}

/* ----- Project work display ----- */
.project-work_title {
    font-size: 1.7rem;
    color: var(--primary-color);
    text-align: center;
    margin: 2rem auto;
}

.project-work_display {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.2%;
    row-gap: 1.2%;
}

.project-work_display img {
    width: 19%;
    height: auto;
    border-radius: 15px;
}

.project-work_display img:hover {
    transform: scale(1.1);
}

/*---------- Art Footer ----------*/
.art_footer {
    display: flex;
    margin-top: 2rem;
}

.art_footer-logo {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #191919;
}

.art_footer-logo h2 {
    color: #fff;
    width: 80%;
    display: flex;
}

.art_footer-logo img {
    width: 80%;
}

.art_footer-des {
    width: 60%;
    display: flex;
    flex-direction: row;
}

.art_footer-blocks {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #d9b93e;
}

.art_footer-block {
    margin: 1rem 4.5rem;
    border-bottom: 1px solid #000;
    padding-bottom: 0.5rem;
}

.art_footer-block h3 {
    font-size: 1.5rem;
}

.art_footer-block p {
    color: white;
    padding-top: 0.1rem;
}

.art_footer-image {
    width: 40%;
    height: auto;
}

.art_footer-image img {
    width: 100%;
    height: auto;
}

/************ Responsive ****************/
@media only screen and (max-width:768px) {
    .project-1_des {
        font-size: 1rem;
        padding: 0;
    }
    .porject1_header h3 {
        font-size: 1.5rem;
        width: 15rem;
    }

    .sense_marketing {
        flex-direction: column;
        margin-top: 3rem;
        padding: 1rem;
    }
    .sense_marketing-des {
        width: 100%;
    }
    .sense_marketing-des p {
        width: 100%;
    }
    .sense_marketing-img {
        width: 100%;
    }

    .branding {
        flex-direction: column;
    }

    .branding_mainImg {
        width: 100%;
    }
    .branding_des {
        width: 100%;
    }
    .branding_des p {
        margin: 0.5rem 0 0;
        font-size: 1rem;
    }
    .brandimg_images {
        width: 100%;
    }
    .brandimg_images img {
        margin-top: 1rem;
    }

    .character_design-imagesup {
        flex-direction: column;
    }
    .character_design-imagesup img {
        width: 100%;
    }
    .character_design-images2 {
        margin: 0;
    }
    .character_design-des {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .character_design-title {
        width: 100%;
    }
    .character_design-des h2 {
        text-align: center;
    }
    .character_design-des p {
        width: 100%;
    }
    .character_design-imagesdown {
        flex-direction: column;
    }
    .character_design-imagesdown img {
        width: 100%;
    }
    .character_design-images {
        margin: 0;
    }

    .package_design {
        flex-direction: column;
    }
    .package_design-des {
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .package_design-des h3 {
        width: 100%;
    }
    .package_design-block h4{
        width: 25%;
    }
    .package_design-img {
        width: 100%;
    }

    .project-block {
        flex-direction: column;
    }
    .project-block_content {
        width: 100%;
    }
    .project-block_img {
        width: 100%;
    }
    .project-work_display img {
        width: 32%;
    }

    .social_design {
        flex-direction: column;
    }
    .social_design-des {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .social_design-img {
        width: 100%;
    }

    .art_footer {
        flex-wrap: wrap;
    }
    .art_footer-logo {
        width: 100%;
    }
    .art_footer-des {
        width: 100%;
        flex-direction: column;
    }
    .art_footer-blocks {
        padding-left: 0.5rem;
        width: 100%;
    }
    .art_footer-block {
        margin: 0;
        margin: 0.5rem 0.5rem;
    }
    .art_footer-image {
        width: 100%;
    }
}