.container {
    width: 100%!important;
}
.hero_wrapper {
    display: flex;
    justify-content: space-between;

}

.hero_content, 
.hero_img {
    width: 50%;
}

.hero_img img {
    width: 100%;
}

.hero_content {
    padding-top: 40px;
}

.hero_content h2 {
    font-size: 3rem;
    line-height: 65px;
}

.light-theme .hero_content-title {
    color: #000;
}

.hero_content p {
    margin-top: 40px;
    font-size: 1.1rem;
}

.highlight {
    color: var(--primary-color);
}

.hero_btns {
    margin-top: 40px;
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.primary_btn, .secondary_btn {
    padding: 0.8rem 1.5rem;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 0.2rem;
    cursor: pointer;
}

.primary_btn {
    background: var(--btn-secondary-bg);
    border: 1px solid var(--btn-primary-bg);
}

.secondary_btn {
    background: var(--btn-primary-bg);
}

@media only screen and (max-width:992px) {
    .hero_content {
        width: 100%!important;
    }
    .hero_content h2 {
        font-size: 2rem;
        line-height: 50px;
    }
    .hero_img {
        width: 100%!important;
        margin: 0;
    }
    .hero_img img {
        width: 80%!important;
    }
}

@media only screen and (max-width:768px) {
    .hero_content h2 {
        font-size: 1.5rem;
        line-height: 45px;
    }

    .hero_content p {
        font-size: 0.9rem;
    }

    .hero_content, .hero_img {
        width: 100%;
    }
}

@media only screen and (max-width:576px) {
    .hero_content, .hero_img {
        width: 100%!important;
    }

    .hero_wrapper {
        flex-direction: column;
    }
}