
.newsletter {
    background: var(--newsletter-bg);

}

.newsletter_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 1rem;
}

.newsletter_content {
    width: 50%;
    text-align: center;
}
.newsletter_form {
    width: 60%;
}

.newsletter_form {
    display: flex;
    flex-direction: column!important;
    padding: 0.4rem 1rem;
    align-items: center;
    justify-content: space-between;
    row-gap: 1rem;
}

.newsletter_form input {
    padding: 0.7rem 1.5rem;
    border: none;
    outline: none;
    font-size: 1rem;
    text-align: start;
    width: 100%;
    background: #fff;
    border-radius: 0.5rem;
}

.newsletter_form textarea {
    width: 100%;
    height: 5rem;
    background: #fff;
    border-radius: 0.5rem;
    padding: 0.7rem 1.5rem;
    border: none;
    outline: none;
}

.light-theme .newsletter {
    background: -webkit-linear-gradient(
        180deg,
        rgba(189, 243, 255, 1) 29%,
        rgba(193, 243, 255, 1) 50%,
        rgba(226, 250, 255, 1) 78%
        );
}

.light-theme .subscribe_btn {
    background: var(--primary-color);
    color: #fff;
}

@media only screen and (max-width:992px) {
    .newsletter_content h2 {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width:768px) {
    .newsletter_wrapper {
        flex-wrap: wrap;
    }
    .newsletter_content, .newsletter_form {
        width: 100%;
    }

    .newsletter_content {
        margin-bottom: 30px;
    }

    .newsletter_form input {
        padding: 8px 20px;
        font-size: 0.9rem;
    }
}